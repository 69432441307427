var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "reset-password" } }, [
    _c(
      "div",
      { attrs: { id: "reset-password__container" } },
      [
        _c("h1", [_vm._v("Resetta la password")]),
        _c("p", [_vm._v("Imposta una nuova password per il tuo account:")]),
        _c("div", { staticClass: "reset-password__input-container" }, [
          _c("p", [
            _c("span", { staticClass: "font-medium" }, [_vm._v("Email:")]),
            _vm._v(" " + _vm._s(_vm.email)),
          ]),
        ]),
        _c("div", { staticClass: "reset-password__input-container" }, [
          _c("div", { staticClass: "flex" }, [
            _c("label", { attrs: { for: "password" } }, [_vm._v("Password:")]),
            _c(
              "a",
              {
                staticClass: "text-sm opacity-70",
                attrs: { id: "passwordIcon" },
                on: { click: _vm.showPassword },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "h-5 w-5",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
          _vm.type === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                class: {
                  hasErrors: _vm.repeatPassword.errors.length > 0,
                },
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.password)
                    ? _vm._i(_vm.password, null) > -1
                    : _vm.password,
                },
                on: {
                  focus: function ($event) {
                    _vm.repeatPassword.errors = []
                  },
                  change: function ($event) {
                    var $$a = _vm.password,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.password = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.password = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.password = $$c
                    }
                  },
                },
              })
            : _vm.type === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                class: {
                  hasErrors: _vm.repeatPassword.errors.length > 0,
                },
                attrs: { type: "radio" },
                domProps: { checked: _vm._q(_vm.password, null) },
                on: {
                  focus: function ($event) {
                    _vm.repeatPassword.errors = []
                  },
                  change: function ($event) {
                    _vm.password = null
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                class: {
                  hasErrors: _vm.repeatPassword.errors.length > 0,
                },
                attrs: { type: _vm.type },
                domProps: { value: _vm.password },
                on: {
                  focus: function ($event) {
                    _vm.repeatPassword.errors = []
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
        ]),
        _c("div", { staticClass: "reset-password__input-container" }, [
          _c("label", { attrs: { for: "repeatPassword" } }, [
            _vm._v("Ripeti la password:"),
          ]),
          _vm.type === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.repeatPassword.value,
                    expression: "repeatPassword.value",
                  },
                ],
                class: {
                  hasErrors: _vm.repeatPassword.errors.length > 0,
                },
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.repeatPassword.value)
                    ? _vm._i(_vm.repeatPassword.value, null) > -1
                    : _vm.repeatPassword.value,
                },
                on: {
                  focus: function ($event) {
                    _vm.repeatPassword.errors = []
                  },
                  change: function ($event) {
                    var $$a = _vm.repeatPassword.value,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.repeatPassword,
                            "value",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.repeatPassword,
                            "value",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.repeatPassword, "value", $$c)
                    }
                  },
                },
              })
            : _vm.type === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.repeatPassword.value,
                    expression: "repeatPassword.value",
                  },
                ],
                class: {
                  hasErrors: _vm.repeatPassword.errors.length > 0,
                },
                attrs: { type: "radio" },
                domProps: { checked: _vm._q(_vm.repeatPassword.value, null) },
                on: {
                  focus: function ($event) {
                    _vm.repeatPassword.errors = []
                  },
                  change: function ($event) {
                    return _vm.$set(_vm.repeatPassword, "value", null)
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.repeatPassword.value,
                    expression: "repeatPassword.value",
                  },
                ],
                class: {
                  hasErrors: _vm.repeatPassword.errors.length > 0,
                },
                attrs: { type: _vm.type },
                domProps: { value: _vm.repeatPassword.value },
                on: {
                  focus: function ($event) {
                    _vm.repeatPassword.errors = []
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.repeatPassword, "value", $event.target.value)
                  },
                },
              }),
        ]),
        _c("ErrorsDisplay", { attrs: { errors: _vm.repeatPassword.errors } }),
        _c("div", { attrs: { id: "reset-password__confirm" } }, [
          _c(
            "button",
            {
              staticClass:
                "bg-[linear-gradient( 168deg, #ff9d00 18.42%, #ffa800 47.15%, #ffd600 85.28%)] rounded-full px-4 py-0.5 text-gray-900 font-semibold uppercase mx-2",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.confirmPassword.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Confermare ")]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
   <p v-if="errors.length > 0" class="text-xs font-extralight">
      *{{ errors.join(" ") }}
   </p>
</template>

<script>
export default {
   name: "ErrorsDisplay",

   props: {
      errors: {
         type: Array,
         default: function() {
            return [];
         },
      },
   },
};
</script>

<style></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.errors.length > 0
    ? _c("p", { staticClass: "text-xs font-extralight" }, [
        _vm._v(" *" + _vm._s(_vm.errors.join(" ")) + " "),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="reset-password">
    <div id="reset-password__container">
      <h1>Resetta la password</h1>

      <p>Imposta una nuova password per il tuo account:</p>
      <div class="reset-password__input-container">
        <p><span class="font-medium">Email:</span> {{ email }}</p>
      </div>
      <div class="reset-password__input-container">
        <div class="flex">
          <label for="password">Password:</label>
          <a id="passwordIcon" class="text-sm opacity-70" @click="showPassword">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              /></svg
          ></a>
        </div>
        <input
          :type="type"
          v-model="password"
          :class="{
            hasErrors: repeatPassword.errors.length > 0,
          }"
          @focus="repeatPassword.errors = []"
        />
      </div>
      <div class="reset-password__input-container">
        <label for="repeatPassword">Ripeti la password:</label>
        <input
          :type="type"
          v-model="repeatPassword.value"
          :class="{
            hasErrors: repeatPassword.errors.length > 0,
          }"
          @focus="repeatPassword.errors = []"
        />
      </div>
      <ErrorsDisplay :errors="repeatPassword.errors" />
      <div id="reset-password__confirm">
        <button
          class="bg-[linear-gradient( 168deg, #ff9d00 18.42%, #ffa800 47.15%, #ffd600 85.28%)] rounded-full px-4 py-0.5 text-gray-900 font-semibold uppercase mx-2"
          @click.prevent="confirmPassword"
        >
          Confermare
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorsDisplay from "@/components/ErrorsDisplay";
import { login, newPassword } from "@/api/authRepository";

export default {
  name: "ResetPassword",

  data() {
    return {
      email: null,
      password: null,
      repeatPassword: { value: null, errors: [] },
      token: null,
      q: this.$route.query.q ? this.$route.query.q : null,
      type: "password",
      userId: null,
    };
  },

  components: { ErrorsDisplay },

  created() {
    if (this.q) {
      const q = JSON.parse(atob(this.q));
      this.token = q.token;
      this.email = q.email;
      this.userId = q.userId;
    }
  },

  methods: {
    showPassword() {
      let icon = document.getElementById("passwordIcon");
      if (this.type === "password") {
        this.type = "text";
        icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
</svg>`;
        setTimeout(() => {
          this.type = "password";
          icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
</svg>`;
        }, 2000);
      } else {
        this.type = "password";
        icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
</svg>`;
      }
    },
    confirmPassword() {
      const passwordVerified = this.passwordVerified();
      if (passwordVerified) {
        newPassword(this.userId, this.token, { new: this.password })
          .then(() => {
            // make auth request and set token
            login({
              loginField: this.email,
              password: this.password,
            })
              .then((response) => {
                this.$store.dispatch(
                  "auth/updateAccessToken",
                  response.data.accessToken
                );
                this.$store.dispatch("auth/updateUserId", response.data.userId);
                this.$store.dispatch(
                  "auth/updateExternalUserId",
                  response.data.externalUserId
                );
                this.$store.dispatch("auth/updateIsAuthenticated", true);

                this.$router.push({ name: "SlotsBar" });
              })
              .catch((err) => {
                this.$router.push({ name: "Login" });
              });
          })
          .catch((err) => {});
      }
    },

    passwordVerified() {
      this.repeatPassword.errors = [];
      if (
        this.password === this.repeatPassword.value &&
        this.password !== null &&
        this.password !== ""
      ) {
        return true;
      } else {
        this.repeatPassword.errors.push("Le password devono essere identiche");
        return false;
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
#reset-password {
  @apply h-full w-full flex items-center justify-center;
  #reset-password__container {
    @apply bg-gray-700 w-11/12 rounded-xl px-7 py-5;

    h1 {
      @apply text-secondary uppercase text-lg font-semibold tracking-wide;
    }

    /* p {
         @apply mb-2.5;
      } */

    .reset-password__input-container {
      @apply mb-3;

      label {
        @apply mb-0.5 font-medium;
      }

      input {
        @apply bg-gray-800 rounded-full text-center w-full py-1 px-3 border-2 border-transparent;
      }
    }

    #reset-password__confirm {
      @apply w-full flex justify-center mt-4;
    }

    .hasErrors {
      border: 2px solid rgb(255, 0, 0) !important;
    }
  }
}
</style>
